<template lang="html">
  <router-view />
</template>

<script>
import { mapMutations } from "vuex";
import store from "@/store";

import { COMMUNITY_FETCH_ACTIVITY } from "@/store/community/activities.module";

export default {
  name: "ActivityView",

  async created() {
    await this.setCurrentActivity();
  },

  watch: {
    $route: "setCurrentActivity",
  },

  methods: {
    ...mapMutations({
      setForceUpdateDefaultActivity: "setForceUpdateDefaultActivity",
    }),
    async setCurrentActivity() {
      if (this.$route.name == "activity.info") {
        return await store.dispatch(COMMUNITY_FETCH_ACTIVITY, {
          activityId: this.$route.params.id,
        });
      }
      if (this.$route.name == "activities") {
        return this.setForceUpdateDefaultActivity(true);
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>
